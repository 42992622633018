<script setup lang="ts">
import type { AvatarSize } from '#ui/types'
import type { User } from '~/types/user'

defineProps<{
  carer: User
  thumbnailSize: AvatarSize
  showOwnerBadge?: boolean
}>()

const clinicStore = useClinicStore()
</script>

<template>
  <div class="size-full">
    <UAvatar
      :src="carer.profile_picture?.small ?? ''"
      :alt="`${carer.first_name} ${carer.last_name}`"
      :size="thumbnailSize"
      loading="lazy"
      class="mb-3"
    />
    <div class="max-w-full">
      <div class="flex justify-center space-x-1">
        <UBadge
          v-if="showOwnerBadge && carer.id === clinicStore.clinic?.owner"
          color="green"
        >
          Proprietário
        </UBadge>
        <UBadge v-else-if="carer.is_admin" color="green">Admin</UBadge>
        <UBadge>Membro</UBadge>
      </div>
      <h3 class="mt-2 truncate text-sm font-medium text-gray-900">
        {{ carer.first_name }} {{ carer.last_name }}
      </h3>
    </div>
  </div>
</template>
